import {
  Form, Input, Button, Modal, Tooltip,
} from 'antd';
import { useParams, Link, useHistory } from 'react-router-dom';
import { AlignLeftOutlined } from '@ant-design/icons';

import { useState } from 'react';
import packageJson from '../../../../../../../package.json';
import { creativesService } from '../../../../../../services';
import { MCreative, MCreativeIecData } from '../../../../../../modeles';
import { YcCard } from '../../../../../../components';
import { IecInputNumber } from '../../../input-number';
import { useAuths } from '../../../../../../contexts';
import { useCreative } from '../../../../context/creative.context';
import { useIec } from '../../../../context/iec.context';
import { playablesService } from '../../../../../../services/playables.service';
import { usePlayable } from '../../../../context/playable.context';

import './setting-general.scss';

const frontVersion = packageJson.version;

export function SettingGeneral() {
  const uHistory = useHistory();
  const uCreative = useCreative();
  const uParams: any = useParams();
  const uIec = useIec();
  const uPlayable = usePlayable();
  const uAuths = useAuths();

  const [isContactModalOpen, setIsContactModalOpen] = useState<boolean>(false);

  const onFinish = (values: any) => {
    values.iecData = uIec.iec.iecData;
    const c: MCreative = {
      ...uCreative.creative,
      ...values,
      applicationId: uParams.appId,
      iecEngineVersion: frontVersion,
    };

    c.schema = getIecSchema(c.iecData);
    c.translations = uIec.iec.translations;

    creativesService.edit(uCreative.creative!.id, c).then((data) => {
      if (data) {
        uCreative.creative = data;
        uCreative.refreshCreative(false);

        if (uCreative.creative.type === 'IEC') {
          uCreative.creative = data;
          uCreative.refreshCreative(false);
        }
      }
    });
  };

  const getIecSchema = (iecData: MCreativeIecData) => {
    const schema = {
      three: {
        videos: [] as any,
      },
      raw: {
        videos: 0,
        globalHover: false,
        endcard: false,
        breaks: 0,
        loops: 0,
        hovers: 0,
      },
    };

    schema.raw.endcard = iecData.endcard.elements.length > 0;
    schema.raw.globalHover = !!iecData.hover;

    iecData.videos.forEach((video) => {
      schema.raw.videos += 1;
      schema.raw.breaks += video.breaks.length;
      schema.raw.loops += video.loops.length;
      schema.raw.hovers += (video.hover && video.hover.elements.length > 0) ? 1 : 0;

      schema.three.videos.push({
        breaks: video.breaks.length,
        loops: video.loops.length,
        hover: !!((video.hover && video.hover.elements.length > 0)),
      });
    });

    return schema;
  };

  const onIterate = () => {
    playablesService.iteratePlayable(uPlayable.playable.id, uPlayable.playableJson, null)
      .then((data: any) => {
        uHistory.push(`/applications/${uParams.appId}/creatives/playable/${data.id}`);
        window.location.reload();
      });
  };

  return (
    <Form id="setting-general" onFinish={onFinish} layout="vertical" className="mb-3">
      <YcCard title="General" icon={<AlignLeftOutlined />} open={false}>
        <div className="row">
          <div className="col-6">
            <Form.Item label="Name" name="name" rules={[{ required: true }]} initialValue={uCreative.creative.name}>
              <Input type="text" />
            </Form.Item>
          </div>
          <div className="col-6">
            <Form.Item label="Creator" name="creator" initialValue={uCreative.creative.User?.name}>
              <Input type="text" disabled />
            </Form.Item>
          </div>
          <div className="col-6">
            <Form.Item label="Version" name="version" initialValue={uCreative.creative.version}>
              <Input type="number" disabled />
            </Form.Item>
          </div>
          <div className="col-6">
            <Form.Item label="Type" name="type" initialValue={uCreative.creative.type}>
              <Input type="text" disabled={uParams.id} />
            </Form.Item>
          </div>
          <div className="col-6">
            <Form.Item label="Play store url" name="playStore" initialValue={uCreative.creative.playStore}>
              <Input type="text" />
            </Form.Item>
          </div>
          <div className="col-6">
            <Form.Item label="App store url" name="appStore" initialValue={uCreative.creative.appStore}>
              <Input type="text" />
            </Form.Item>
          </div>
          <div className="col-12" />
          {
            uCreative.creative.type === 'IEC' && (
              <>
                <div className="col-6">
                  <IecInputNumber label="Zoom Portrait" field="zoomPortrait" obj={uIec.iec.iecData} step={0.01} />
                </div>
                <div className="col-6">
                  <IecInputNumber label="Zoom Landscape" field="zoomLandscape" obj={uIec.iec.iecData} step={0.01} />
                </div>
              </>
            )
          }
        </div>
      </YcCard>
      <div className="buttons-br">
        {
          !uAuths.isFreeAccount()
            ? (
              <Link to={`/applications/${uParams.appId}/export/${uCreative.creative.id}`}>
                <Button type="link" disabled={uPlayable.playable.status !== 'succeeded' && uPlayable.playable.status !== 'null'}>Export</Button>
              </Link>
            )
            : (
              <Tooltip title="Your account is on FREE mode, contact us at: contact@monsterplayable.com!">
                <Button type="link" disabled={uAuths.isFreeAccount()}>
                  Export
                </Button>
              </Tooltip>
            )
        }
        <Button type="primary" htmlType="submit" disabled={uPlayable.playable.status !== 'succeeded' && uPlayable.playable.status !== 'null'}>
          Save
        </Button>
        {
          uCreative.creative.type === 'PLA' && (
            <Button
              type="primary"
              onClick={() => { onIterate(); }}
              disabled={uPlayable.playable.status !== 'succeeded' && uPlayable.playable.status !== 'null'}
            >
              Save & Build
            </Button>
          )
        }
        <Modal
          title="Export IEC unabled"
          open={isContactModalOpen}
          onCancel={() => { setIsContactModalOpen(false); }}
          footer={[
            <a href="mailto:contact@monsterplayable.com">Contact Us</a>,
          ]}
        >
          <p>Your account is on <b>FREE</b> mode, contact us at: contact@monsterplayable.com!</p>
        </Modal>
      </div>
    </Form>
  );
}
