import { MAccount, MTag } from './account.m';
import { MDocument } from './document.m';
import { MCreative } from './creative.m';

export class MApplication {
  id: number = 0;
  name: string = '';
  key: string = '';
  appStore?: string;
  playStore?: string;
  iconId?: number;
  Icon?: MDocument;
  Iecs?: MCreative[];
  tags?: MTag[] = [];
  defaultColor?: string;
  createdAt: Date = new Date();
  deletedAt?: Date;
  Account?: MAccount;
}
