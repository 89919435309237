import {
  Button, Input, Switch, Tooltip,
} from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CheckOutlined, CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck, faXmark, faUserCrown,
} from '@fortawesome/pro-regular-svg-icons';
import { faHubspot } from '@fortawesome/free-brands-svg-icons';
import TextArea from 'antd/lib/input/TextArea';

import {
  MpColumnType, MpTable, YcUserModal,
} from '../../../../components';
import { useAuths, useUserEdited } from '../../../../contexts';
import {
  MAccount, MApplication, MCreative, MCreativeExport,
} from '../../../../modeles';
import { adminAccountsService } from '../../../../services';
import { getBooleanFilter, getUniqueList, isMonthly } from '../../../../tools';

import './accounts.page.scss';

export function AccountsPage() {
  const uAuths = useAuths();
  const uUserEdited = useUserEdited();

  const [accounts, _setAccounts] = useState<MAccount[]>([]);
  const [accountsCopy, _setAccountsCopy] = useState<MAccount[]>([]);

  useEffect(() => {
    adminAccountsService.listing().then((data) => {
      _setAccounts(data || []);
      _setAccountsCopy(data || []);
    });
  }, []);

  useEffect(() => {
    if (uUserEdited.user === undefined) {
      adminAccountsService.listing().then((data) => {
        _setAccounts(data || []);
      });
    }
  }, [uUserEdited.user]);

  const columns: MpColumnType<MAccount>[] = [
    {
      title: 'Active',
      key: 'isActive',
      render: (text: string, account: MAccount) => (
        <Switch
          disabled={account.id === uAuths.user?.accountId}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          defaultChecked={account.isActive}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={async (e) => {
            account.isActive = e;
            _setAccounts(await adminAccountsService.isActive(account.id, e));
          }}
        />
      ),
      sorter: (a: MAccount, b: MAccount) => (a.isActive || b.isActive ? 1 : 0),
      filters: getBooleanFilter(),
      onFilter: (value: any, record: MAccount) => record.isActive === (value as boolean) || false,
    },
    {
      title: 'ID',
      key: 'id',
      render: (text: string, account: MAccount) => (
        <Link to={`/admin/accounts/account/${account.id}`}>
          #
          {account.id}
        </Link>
      ),
      defaultSortOrder: 'descend',
      sorter: (a: MAccount, b: MAccount) => a.id - b.id,
    },
    {
      title: 'Name',
      key: 'name',
      render: (text: string, account: MAccount) => (
        <Link to={`/admin/accounts/account/${account.id}`}>
          {account.name}
        </Link>
      ),
      sorter: {
        compare: (a: MAccount, b: MAccount) => a.name.localeCompare(b.name || '') || -1,
        multiple: 1,
      },
      filters: getUniqueList(accounts, 'name').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MAccount) => record.name === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'Users',
      key: 'users',
      render: (text: string, account: MAccount) => (
        <label>{account.Users?.length}</label>
      ),
      sorter: (a: MAccount, b: MAccount) => (a.Users?.length || 0) - (b.Users?.length || 0),
    },
    {
      title: 'Total Impressions',
      key: 'totalImpressions',
      render: (text: string, account: MAccount) => (
        <label>{account.totalImpressions.toLocaleString()}</label>
      ),
      sorter: (a: MAccount, b: MAccount) => (a.totalImpressions) - (b.totalImpressions),
    },
    {
      title: 'Applicatons (Monthly / Active / TT)',
      key: 'applications',
      render: (text: string, account: MAccount) => (
        <label>{`
        ${account.Applications?.filter((c: MApplication) => isMonthly(new Date(c.createdAt))).length}
        / ${account.Applications?.filter((c: MApplication) => !c.deletedAt).length}
        / ${account.Applications?.length}`}
        </label>
      ),
      sorter: (a: MAccount, b: MAccount) => (a.Applications?.length || 0) - (b.Applications?.length || 0),
    },
    {
      title: 'Iecs (Monthly / Active / TT)',
      key: 'iecs',
      render: (text: string, account: MAccount) => (
        <label>{`
        ${account.Iec?.filter((c: MCreative) => isMonthly(new Date(c.createdAt!))).length}
        / ${account.Iec?.filter((c: MCreative) => !c.deletedAt).length}
        / ${account.Iec?.length}`}
        </label>
      ),
      sorter: (a: MAccount, b: MAccount) => (a.Iec?.length || 0) - (b.Iec?.length || 0),
    },
    {
      title: 'Exports (Monthly / Active / TT )',
      key: 'exports',
      render: (text: string, account: MAccount) => (
        <label>
          {`
          ${account.CreativeExport?.filter((c: MCreativeExport) => isMonthly(new Date(c.createdAt!))).length}
          / ${account.CreativeExport?.filter((c: MCreativeExport) => !c.deletedAt).length}
          / ${account.CreativeExport?.length}
          `}
        </label>
      ),
      sorter: (a: MAccount, b: MAccount) => (a.CreativeExport?.length || 0) - (b.CreativeExport?.length || 0),
    },
    {
      title: 'Free account',
      key: 'isFree',
      render: (text: string, account: MAccount) => (
        <Switch
          disabled={account.id === uAuths.user?.accountId}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          defaultChecked={account.isFree}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={async (e) => {
            account.isFree = e;
            _setAccounts(await adminAccountsService.isFree(account.id, e));
          }}
        />
      ),
      sorter: (a: MAccount, b: MAccount) => (a.isFree || b.isFree ? 1 : 0),
      filters: getBooleanFilter(),
      onFilter: (value: any, record: MAccount) => record.isFree === (value as boolean) || false,
    },
    {
      title: 'Google Ads',
      key: 'googleAds',
      render: (text: string, account: MAccount) => (
        <>
          {account.isGoogleAd && (
            <FontAwesomeIcon icon={faCheck} color="green" />
          )}
          {!account.isGoogleAd && (
            <FontAwesomeIcon icon={faXmark} color="red" />
          )}
        </>
      ),
      sorter: (a: MAccount, b: MAccount) => (a.mailValidated || b.mailValidated ? 1 : 0),
    },
    {
      title: 'Mail validated',
      key: 'mailValidated',
      render: (text: string, account: MAccount) => (
        account.mailValidated
          ? (
            <FontAwesomeIcon icon={faCheck} color="green" />
          )
          : (
            <FontAwesomeIcon icon={faXmark} color="red" />
          )
      ),
      sorter: (a: MAccount, b: MAccount) => (a.mailValidated || b.mailValidated ? 1 : 0),
    },
    {
      title: 'Creation date',
      key: 'createdAt',
      render: (text: string, account: MAccount) => (
        <label>{new Date(account.createdAt!).toLocaleString()}</label>
      ),
      sorter: (a: MAccount, b: MAccount) => a.createdAt?.toString()?.localeCompare(b.createdAt?.toString() || '') || -1,
    },
    {
      title: 'Description',
      key: 'description',
      render: (text: string, account: MAccount) => (
        <div className="description">
          <TextArea
            defaultValue={account.description}
            onChange={(e) => editDescription(e.target.value as string, account)}
            placeholder="Description"
            bordered={false}
            autoSize={{ minRows: 1, maxRows: 3 }}
          />
          {
            accountsCopy.find((ac: MAccount) => ac.id === account.id)?.description !== account.description
            && (
              <Tooltip title="search">
                <Button
                  onClick={() => { saveDescription(account); }}
                  className="save-description"
                  type="primary"
                  size="small"
                  shape="circle"
                  icon={<SaveOutlined />}
                />
              </Tooltip>
            )
          }
        </div>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text: string, account: MAccount) => (
        <div className="d-flex justify-content-around">
          <Tooltip title="Hubspot link">
            <Button
              disabled={!account.hubspotId}
              type="primary"
              // @ts-ignore
              icon={<FontAwesomeIcon icon={faHubspot} title="Font weight" />}
              size="middle"
              shape="circle"
              onClick={() => {
                window.open(`https://app-eu1.hubspot.com/contacts/26656886/record/0-2/${account.hubspotId}`, '_blank', 'noreferrer');
              }}
            />
          </Tooltip>
          <Tooltip title="Log as S-Admin">
            <Button
              danger
              type="primary"
              icon={<FontAwesomeIcon icon={faUserCrown} title="Font weight" />}
              size="middle"
              shape="circle"
              onClick={() => {
                loginAs(account);
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const loginAs = (account: MAccount) => {
    const adminUser = account.Users?.find((user) => user.isAdmin)
      ? account.Users.find((user) => user.isAdmin)
      : null;
    if (adminUser) {
      uAuths.logAs(adminUser.id, true);
    }
  };

  const editDescription = (description: string, account: MAccount) => {
    const accountList = [...accounts];
    accountList.find((a: MAccount) => a.id === account.id)!.description = description;
    _setAccounts(accountList);
  };

  const saveDescription = async (account: MAccount) => {
    const accountList = [...accountsCopy];
    accountList.find((a: MAccount) => a.id === account.id)!.description = account.description;
    _setAccountsCopy(accountList);

    await adminAccountsService.saveDescription(account);
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    _setAccounts(
      accountsCopy.filter(
        (account) => account.name
          .toLowerCase()
          .includes(value.toLowerCase() as string) || account.id.toString() === value.toString(),
      ),
    );
  };

  return (
    <div id="accounts">
      <Input placeholder="Search" onChange={onSearch} style={{ width: '30%', marginBottom: '20px' }} />
      <MpTable
        bordered
        size="small"
        dataSource={accounts}
        columns={columns}
        rowKey="id"
        mpTableKey="accounts"
        urlParamsEnabled
      />
      <YcUserModal />
    </div>
  );
}
