import { Input } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  MpColumnType, MpTable, YcUserModal,
} from '../../../../components';
import { MApplication } from '../../../../modeles';
import { adminApplicationsService } from '../../../../services';
import { getUniqueList } from '../../../../tools';

import './applications.page.scss';

export function ApplicationsPage() {
  const [applications, _setApplications] = useState<MApplication[]>([]);
  const [applicationsCopy, _setApplicationsCopy] = useState<MApplication[]>([]);

  useEffect(() => {
    adminApplicationsService.listing().then((data) => {
      _setApplications(data || []);
      _setApplicationsCopy(data || []);
    });
  }, []);

  const columns: MpColumnType<MApplication>[] = [
    {
      title: 'Account ID',
      key: 'accountId',
      width: '6%',
      render: (text: string, application: MApplication) => (
        <Link to={`/admin/accounts/account/${application.Account?.id}`}>
          #
          {application.Account?.id}
        </Link>
      ),
      defaultSortOrder: 'descend',
      sorter: (a: MApplication, b: MApplication) => (a.Account?.id || 0) - (b.Account?.id || 0),
    },
    {
      title: 'Account',
      key: 'accountName',
      width: '10%',
      render: (text: string, application: MApplication) => (
        <Link to={`/admin/accounts/account/${application.Account?.id}`}>
          {application.Account?.name}
        </Link>
      ),
      sorter: {
        compare: (a: MApplication, b: MApplication) => a.Account?.name.localeCompare(b.Account?.name || '') || -1,
      },
      filters: getUniqueList(applications, 'Account.name').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MApplication) => record.Account?.name === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'ID',
      key: 'id',
      width: '5%',
      render: (text: string, application: MApplication) => (
        <Link to={`/applications/${application.id}/creatives`}>
          {application.id}
        </Link>
      ),
      defaultSortOrder: 'descend',
      sorter: (a: MApplication, b: MApplication) => a.id - b.id,
    },
    {
      title: 'Name',
      key: 'name',
      render: (text: string, application: MApplication) => (
        <Link to={`/applications/${application.id}/creatives`}>
          {application.name}
        </Link>
      ),
      sorter: {
        compare: (a: MApplication, b: MApplication) => a.name.localeCompare(b.name || '') || -1,
        multiple: 1,
      },
      filters: getUniqueList(applications, 'name').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MApplication) => record.name === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'Key',
      key: 'key',
      render: (text: string, application: MApplication) => (
        application.key
      ),
      sorter: {
        compare: (a: MApplication, b: MApplication) => a.key.localeCompare(b.key || '') || -1,
        multiple: 1,
      },
      filters: getUniqueList(applications, 'key').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MApplication) => record.key === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'Creation date',
      key: 'createdAt',
      render: (text: string, account: MApplication) => (
        <label>{new Date(account.createdAt!).toLocaleString()}</label>
      ),
      sorter: (a: MApplication, b: MApplication) => a.createdAt?.toString()?.localeCompare(b.createdAt?.toString() || '') || -1,
    },
  ];

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    _setApplications(
      applicationsCopy.filter(
        (application) => application.name.toLowerCase().includes(value.toLowerCase() as string)
          || application.key.toLowerCase().includes(value.toLowerCase() as string)
          || application.id.toString() === value.toString()
          || application.Account?.id.toString() === value.toString()
          || application.Account?.name.toLowerCase().includes(value.toLowerCase() as string),
      ),
    );
  };

  return (
    <div id="applications">
      <Input placeholder="Search" onChange={onSearch} style={{ width: '30%', marginBottom: '20px' }} />
      <MpTable
        bordered
        size="small"
        dataSource={applications}
        columns={columns}
        rowKey="id"
        mpTableKey="applications"
        urlParamsEnabled
      />
      <YcUserModal />
    </div>
  );
}
