import {
  Button, Input, Switch, Tooltip,
} from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCrown } from '@fortawesome/pro-regular-svg-icons';

import { MpColumnType, MpTable, YcUserModal } from '../../../../components';
import { useAuths, useUserEdited } from '../../../../contexts';
import { MUser } from '../../../../modeles';
import { adminUsersService } from '../../../../services';
import { getBooleanFilter, getUniqueList } from '../../../../tools';

import './users.page.scss';

export function UsersPage() {
  const uAuths = useAuths();
  const uUserEdited = useUserEdited();

  const [users, _setUsers] = useState<MUser[]>([]);
  const [usersCopy, _setUsersCopy] = useState<MUser[]>([]);

  useEffect(() => {
    adminUsersService.listing().then((data) => {
      _setUsers(data || []);
      _setUsersCopy(data || []);
    });
  }, []);

  useEffect(() => {
    if (uUserEdited.user === undefined) {
      adminUsersService.listing().then((data) => {
        _setUsers(data || []);
      });
    }
  }, [uUserEdited.user]);

  const columns: MpColumnType<MUser>[] = [
    {
      title: 'Account Active',
      key: 'accountIsActive',
      width: '6%',
      render: (text: string, user: MUser) => (
        <Switch
          disabled
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          defaultChecked={user.Account?.isActive}
        />
      ),
      sorter: (a: MUser, b: MUser) => (a.isActive || b.isActive ? 1 : 0),
      filters: getBooleanFilter(),
      onFilter: (value: any, record: MUser) => record.isActive === (value as boolean) || false,
    },
    {
      title: 'Account ID',
      key: 'accountId',
      width: '6%',
      render: (text: string, user: MUser) => (
        <Link to={`/admin/accounts/account/${user.Account?.id}`}>
          #
          {user.Account?.id}
        </Link>
      ),
      defaultSortOrder: 'descend',
      sorter: (a: MUser, b: MUser) => (a.Account?.id || 0) - (b.Account?.id || 0),
    },
    {
      title: 'Account',
      key: 'accountName',
      width: '10%',
      render: (text: string, user: MUser) => (
        <Link to={`/admin/accounts/account/${user.Account?.id}`}>
          {user.Account?.name}
        </Link>
      ),
      sorter: {
        compare: (a: MUser, b: MUser) => a.Account?.name.localeCompare(b.Account?.name || '') || -1,
      },
      filters: getUniqueList(users, 'Account.name').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MUser) => record.Account?.name === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'Active',
      key: 'isActive',
      width: '6%',
      render: (text: string, user: MUser) => (
        <Switch
          disabled={user.id === uAuths.user?.id}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          defaultChecked={user.isActive}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={(e) => {
            user.isActive = e;
            adminUsersService.isActive(user.id, e);
          }}
        />
      ),
      sorter: (a: MUser, b: MUser) => (a.isActive || b.isActive ? 1 : 0),
      filters: getBooleanFilter(),
      onFilter: (value: any, record: MUser) => record.isActive === (value as boolean) || false,
    },
    {
      title: 'ID',
      key: 'id',
      width: '5%',
      render: (text: string, user: MUser) => (
        user.id
      ),
      defaultSortOrder: 'descend',
      sorter: (a: MUser, b: MUser) => a.id - b.id,
    },
    {
      title: 'Email',
      key: 'email',
      render: (text: string, user: MUser) => (
        user.email
      ),
      sorter: {
        compare: (a: MUser, b: MUser) => a.email.localeCompare(b.email || '') || -1,
        multiple: 1,
      },
      filters: getUniqueList(users, 'email').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MUser) => record.email === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'First name',
      key: 'firstName',
      render: (text: string, user: MUser) => (
        <Link to={`/admin/accounts/account/${user.id}`}>
          {user.firstName}
        </Link>
      ),
      sorter: {
        compare: (a: MUser, b: MUser) => a.firstName.localeCompare(b.firstName || '') || -1,
        multiple: 1,
      },
      filters: getUniqueList(users, 'firstName').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MUser) => record.firstName === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'Last name',
      key: 'lastName',
      render: (text: string, user: MUser) => (
        <Link to={`/admin/accounts/account/${user.id}`}>
          {user.lastName}
        </Link>
      ),
      sorter: {
        compare: (a: MUser, b: MUser) => a.lastName.localeCompare(b.lastName || '') || -1,
        multiple: 1,
      },
      filters: getUniqueList(users, 'lastName').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MUser) => record.lastName === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text: string, user: MUser) => (
        <div className="d-flex justify-content-around">
          <Tooltip title="Log as S-Admin">
            <Button
              danger
              type="primary"
              icon={<FontAwesomeIcon icon={faUserCrown} title="Font weight" />}
              size="middle"
              shape="circle"
              disabled={!user.isActive}
              onClick={() => {
                uAuths.logAs(user.id, true);
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    _setUsers(
      usersCopy.filter(
        (user) => user.firstName.toLowerCase().includes(value.toLowerCase() as string)
          || user.lastName.toString() === value.toString()
          || user.id.toString() === value.toString()
          || user.email.toLowerCase().includes(value.toLowerCase() as string)
          || user.Account?.id.toString() === value.toString()
          || user.Account?.name.toLowerCase().includes(value.toLowerCase() as string),
      ),
    );
  };

  return (
    <div id="users">
      <Input placeholder="Search" onChange={onSearch} style={{ width: '30%', marginBottom: '20px' }} />
      <MpTable
        bordered
        size="small"
        dataSource={users}
        columns={columns}
        rowKey="id"
        mpTableKey="users"
        urlParamsEnabled
      />
      <YcUserModal />
    </div>
  );
}
