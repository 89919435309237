import { MAccount } from '../../modeles';
import { BaseService } from '../base.service';

class AdminAccountsService extends BaseService {
  PATH = 'admin/accounts/';

  async view(id: number): Promise<MAccount | null> {
    return this._view(`${this.PATH}/view/${id}`);
  }

  async listing(): Promise<MAccount[] | null> {
    return this._listing(`${this.PATH}listing`);
  }

  async edit(id: number, account: MAccount): Promise<MAccount | null> {
    return this._edit(`${this.PATH}edit/${id}`, account);
  }
  async isActive(id: number, isActive: boolean): Promise<MAccount[]> {
    return this._listing(`${this.PATH}is-active/${id}/${isActive}`);
  }

  async isFree(id: number, isFree: boolean): Promise<MAccount[]> {
    return this._listing(`${this.PATH}is-free/${id}/${isFree}`);
  }

  async saveDescription(account: MAccount): Promise<MAccount | null> {
    return this._edit(`${this.PATH}save-description/`, account);
  }

  async listingForListing(): Promise<MAccount[] | null> {
    return this._listing(`${this.PATH}listing-for-listing`);
  }
}

export const adminAccountsService = new AdminAccountsService();
