/* eslint-disable react/jsx-no-bind */
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Table, {
  ColumnsType, ColumnType, TablePaginationConfig, TableProps,
} from 'antd/lib/table';
import qs from 'qs';
import { Col, Row } from 'antd';

import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { MpTableHideMenu } from './tableHideMenu';

export interface MpColumnType<T> extends ColumnType<T> {
  mpSort?: 'string' | 'number',
  mpCanNotHide?: boolean,
  children?: Array<MpColumnType<T>>;
}

export interface MpTableProps<T extends object = any> extends TableProps<T> {
  columns: MpColumnType<T>[],
  mpTableKey: string,
  mpDisableTotal?: boolean,
  urlParamsEnabled?: boolean,
  horizontalScrollEnabled?: boolean,
  defaultPageSize?: number,
  mpSummarys?: Array<{
    key: string,
    value?: any,
  }>
}

export function MpTable<T extends object = any>({
  columns,
  mpTableKey,
  mpSummarys,
  mpDisableTotal,
  urlParamsEnabled = false,
  horizontalScrollEnabled = false,
  defaultPageSize = 50,
  ...props
}: MpTableProps<T>) {
  const uHistory = useHistory();

  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);

  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  const [sortedInfo, _setSortedInfo] = useState<{ field: string, order: string }[]>([]);
  const [filteredInfo, _setFilteredInfo] = useState<{ field: string, filters: string[] }[]>([]);
  const [pagination, _setPagination] = useState<TablePaginationConfig>({
    defaultPageSize: defaultPageSize || 50,
    showSizeChanger: true,
    pageSizeOptions: ['10', '25', '50', '75', '100', '150', '200'],
  });

  useEffect(() => {
    if (urlParamsEnabled) {
      _setSortedInfo(queryParameters.get('sorter')?.split(',').map((sort) => {
        return {
          field: sort.split('-')[0],
          order: sort.split('-')[1] === 'A' ? 'ascend' : 'descend',
        };
      }) || []);
      _setFilteredInfo(queryParameters.get('filters')?.split(';').map((filter) => {
        return {
          field: filter.split('-')[0],
          filters: filter.split('-')[1].split(','),
        };
      }) || []);
      _setPagination({
        ...pagination,
        current: queryParameters.has('currentPagination')
          ? +queryParameters.get('currentPagination')!
          : 1,
        pageSize: queryParameters.has('sizePagination')
          ? +queryParameters.get('sizePagination')!
          : defaultPageSize || 50,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (urlParamsEnabled) {
      editUrlParams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  const editUrlParams = () => {
    uHistory.push({
      search: getParams(true) as string,
    });
  };

  const getParams = (stringify: boolean) => {
    const params = {
      currentPagination: pagination.current,
      sizePagination: pagination.pageSize,
      ...(sortedInfo.length !== 0 ? { sorter: sortedInfo.map((sort) => `${sort.field}-${sort.order === 'ascend' ? 'A' : 'D'}`).join() } : {}),
      ...(filteredInfo.length !== 0 ? { filters: filteredInfo.map((filter) => `${filter.field}-${filter.filters.join()}`).join(';') } : {}),
    };
    if (stringify) {
      return qs.stringify(params, { encode: false });
    }
    return params;
  };

  const columnsNew = (columns.map((c) => {
    const column = {
      ellipsis: false,
      title: c.dataIndex,
      key: c.key || (c.dataIndex) as string,
      dataIndex: c.dataIndex || c.key,
      ...c,
      sortOrder: sortedInfo.find((sort) => sort.field === c.key) ? sortedInfo.find((sort) => sort.field === c.key)?.order : null,
      filteredValue: filteredInfo.find((filter) => filter.field === c.key) ? filteredInfo.find((filter) => filter.field === c.key)?.filters : [],
    };
    // manage sorter
    sortColumn(column);
    if (column.children) {
      column.children.forEach((child) => sortColumn(child));
      column.children = column.children.filter((child) => !(hiddenFields.indexOf(child.key as string) !== -1));
    }
    return column;
  })).filter((c) => !(hiddenFields.indexOf(c.key as string) !== -1)) as ColumnsType<T>;

  function sortColumn(column: any) {
    if (column.mpSort) {
      const key = (column.dataIndex) as string;
      if (column.mpSort === 'number') {
        column.sorter = (a: any, b: any) => (+a[key]) - (+b[key]);
      } else if (column.mpSort === 'string') {
        column.sorter = (a: any, b: any) => `${a[key] as string}`.localeCompare(`${b[key] as string}`);
      }
      column.sortOrder = sortedInfo.find((sort) => sort.field === column.key) ? sortedInfo.find((sort) => sort.field === column.key)?.order : null;
    }
  }

  function renderHeader() {
    return (
      <Row justify="end" align="middle" gutter={8}>
        <Col>
          <MpTableHideMenu
            onChange={(fields) => setHiddenFields(fields as string[])}
            tableKey={mpTableKey}
            columns={columns}
          />
        </Col>
      </Row>
    );
  }

  let summary: any;
  if (mpSummarys) {
    summary = () => (
      <Table.Summary.Row style={{ backgroundColor: '#fafafa' }}>
        {mpSummarys.filter((c) => !(hiddenFields.indexOf(c.key) !== -1)).map(({ value, ...rest }, index: number) => (
          <Table.Summary.Cell index={index} {...rest}>
            {' '}
            {value}
            {' '}
          </Table.Summary.Cell>
        ))}
      </Table.Summary.Row>
    );
  }

  const x = columnsNew.reduce((sum, column) => {
    const columnWidth = typeof column.width === 'string' ? 0 : column.width || 0;
    return sum + columnWidth;
  }, 0);

  const handleTableChange = (
    newPagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<T> | SorterResult<any>[],
  ) => {
    _setPagination({ ...newPagination });
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const setSortedInfo = (sorter: SorterResult<T> | SorterResult<any>[]) => {
    if (Array.isArray(sorter)) {
      _setSortedInfo(sorter.map((sort) => {
        return { field: sort.field as string, order: sort.order as string };
      }));
    } else if (sorter.order) {
      _setSortedInfo([{ field: sorter.field as string, order: sorter.order as string }]);
    } else {
      _setSortedInfo([]);
    }
  };

  const setFilteredInfo = (filters: Record<string, FilterValue | null>) => {
    const newFilters = Object.keys(filters).map((key) => {
      return { field: key, filters: filters[key] as string[] };
    });
    _setFilteredInfo(newFilters.filter((filter) => Array.isArray(filter.filters) && filter.filters.length !== 0));
  };

  return (
    <Table
      dataSource={props.dataSource}
      columns={columnsNew}
      summary={summary}
      pagination={pagination}
      onChange={handleTableChange}
      // TODO remove this lint error
      // eslint-disable-next-line react/no-unstable-nested-components
      footer={!mpDisableTotal ? () => (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {filteredInfo.length > 0
            ? <b>{`Total search: ${0} of ${props.dataSource?.length.toLocaleString()}`}</b>
            : <b>{`Total: ${props.dataSource?.length.toLocaleString()} `}</b>}
        </div>
      ) : undefined}
      title={renderHeader}
      scroll={horizontalScrollEnabled ? { x } : {}}
      {...props}
    />
  );
}
