import { MColor } from '../modeles';

export class ColorTools {
  static mColorToString(color: MColor) {
    return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
  }

  static mToRgba(newColor: any) {
    return {
      r: newColor.r,
      g: newColor.g,
      b: newColor.b,
      a: newColor.a,
      type: 'Color',
    };
  }

  static mToWebRgba(newColor: any) {
    return {
      r: Math.round(newColor.r * 255),
      g: Math.round(newColor.g * 255),
      b: Math.round(newColor.b * 255),
      a: newColor.a,
    };
  }

  static mToRgbaUnity(newColor: any) {
    return {
      r: Math.round(newColor.r / 255),
      g: Math.round(newColor.g / 255),
      b: Math.round(newColor.b / 255),
      a: newColor.a,
    };
  }
}
