import { MCreative } from '../modeles';
import { BaseService } from './base.service';

class PlayablesService extends BaseService {
  PATH = 'playables/';

  async iteratePlayable(id: number, playableJson: any | null, newEngineVersion: string | null): Promise<any | null> {
    return this._edit(`${this.PATH}iterate-playable/${id}`, { playableJson, newEngineVersion });
  }

  async buildPlayableRelease(creativeId: number): Promise<any | null> {
    return this._view(`${this.PATH}build-release/${creativeId}`);
  }

  async playableReleaseStatus(creativeId: number): Promise<MCreative | null> {
    return this._view(`${this.PATH}playable-release/${creativeId}`);
  }

  async playableGenerateQrcode(creativeId: number, blob: Blob): Promise<any | null> {
    const formData = new FormData();
    formData.append('playable', blob, 'playable.html');
    formData.append('creativeId', creativeId.toString());

    return this._add(`${this.PATH}playable-generate-qrcode`, formData);
  }
}

export const playablesService = new PlayablesService();
