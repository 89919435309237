import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { IPermission } from '../modeles';
import { authsService } from '../services/auths.service';
import { IToken, ITokens } from '../services/base.service';

class UserAuthAccount {
  id: number = 0;
  name: string = '';
  isFree: boolean = true;
}

export class UserAuth {
  id: number = 0;
  accountId: number = 0;
  name: string = '';
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  isAdmin: boolean = false;
  isFreeAccount: boolean = true;
  isSuperAdmin: boolean = false;
  Account: UserAuthAccount = new UserAuthAccount();
  permissions: any;
  tours: any;
  adminLogged?: UserAuth;
}

export interface AuthsContextType {
  user: UserAuth | null;
  signUp: boolean;
  _setUser: React.Dispatch<React.SetStateAction<UserAuth | null>>;
  isLogin: () => boolean;
  isAdmin: () => boolean;
  isReady: () => boolean;
  isFreeAccount: () => boolean;
  isSuperAdmin: () => boolean;
  isSuperAdminLogAs: () => boolean;
  logAs: (id: number, asSuperAdmin?: boolean) => Promise<void>;
  signup: (data: any) => Promise<void>;
  login: (data: any) => Promise<void>;
  autologin: () => Promise<void>;
  logout: () => Promise<void>;
  canAccess: (access: string) => boolean;
  canAccessRec: (permissions: any, access: string[]) => boolean;
  tourDone: (tour: string) => boolean;
  getTokens: () => ITokens;
  setToken: (token: string) => void
  removeToken: (token: IToken) => void
}

const AuthsContext = React.createContext<AuthsContextType | null>(null);

export function AuthsContextProvider({ children }: { children: React.ReactNode }) {
  const [user, _setUser] = useState<UserAuth | null>(null);
  const [ready, _setReady] = useState(false);
  const [signUp, _setSignUp] = useState<boolean>(false);

  const uHistory = useHistory();
  const uLocation = useLocation();

  // TODO remove eslint-disable
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value: AuthsContextType = {
    user,
    signUp,
    _setUser,

    isLogin() {
      return !!user;
    },

    isReady() {
      return ready;
    },

    isAdmin() {
      // eslint-disable-next-line react/no-this-in-sfc
      return this.isLogin() && (!!user?.isAdmin || !!user?.adminLogged?.isAdmin);
    },

    isSuperAdmin() {
      // eslint-disable-next-line react/no-this-in-sfc
      return this.isLogin() && (!!user?.isSuperAdmin || !!user?.adminLogged?.isSuperAdmin);
    },

    isSuperAdminLogAs() {
      // eslint-disable-next-line react/no-this-in-sfc
      return !!user?.adminLogged?.isSuperAdmin;
    },

    isFreeAccount() {
      // eslint-disable-next-line react/no-this-in-sfc
      return this.isLogin() && !!user?.Account.isFree;
    },

    getTokens(): ITokens {
      return authsService.getTokens();
    },

    setToken(token: string) {
      authsService.setToken(token);
      window.location.reload();
    },

    removeToken(token: IToken) {
      authsService.removeToken(token);
    },

    async signup(data: any) {
      authsService.setToken('');
      const newUser = await authsService.signup(data);
      if (newUser != null) {
        _setUser(newUser);
        uHistory.push('/');
      } else if (authsService.getToken()) {
        _setSignUp(true);
      }
    },

    async login(data: any) {
      const newUser = await authsService.login(data);
      if (newUser != null) {
        _setUser(newUser);
        uHistory.push('/');
      }
    },

    async autologin() {
      const newUser = await authsService.autologin();
      if (newUser) {
        _setUser(newUser);
        if (uLocation.pathname === '/login') {
          uHistory.push('/');
        }
      } else {
        const split = uLocation.pathname.split('/');
        const page = split?.[1] || '';
        if (!['',
          'home',
          'pricing',
          'sign-up',
          'forgot-password',
          'reset-password',
          'active-account',
          'privacy-policies',
          'iec-presentation',
          'playable-presentation',
        ].includes(page)) {
          uHistory.push('/login');
        }
      }
      _setReady(true);
    },
    async logout() {
      await authsService.logout();
      _setUser(null);
    },

    canAccessRec(permissions: IPermission, access: string[]): boolean {
      if (!permissions) {
        return false;
      }
      const perm = permissions[access[0]];
      if (perm && perm.active === true) {
        if (access.length === 1) {
          return perm.active as boolean;
        }
        // eslint-disable-next-line react/no-this-in-sfc
        return this.canAccessRec(permissions[access[0]].children, access.slice(1)) as boolean;
      }
      return false;
    },

    canAccess(access: string):boolean {
      if (user) {
        if (user.isSuperAdmin) {
          return true;
        }
        if (user.permissions) {
          // eslint-disable-next-line react/no-this-in-sfc
          return this.canAccessRec(user.permissions, access?.split('/') || []) as boolean;
        }
      }
      return false;
    },

    tourDone(tour: string):boolean {
      if (user) {
        if (user.tours) {
          return user.tours[tour].finished as boolean;
        }
      }
      return false;
    },

    async logAs(id: number, asSuperAdmin: boolean = false) {
      if (!!user?.isSuperAdmin || !!user?.adminLogged?.isSuperAdmin) {
        await authsService.loginAsUser(id, asSuperAdmin);
        uHistory.push('/applications');
        window.location.reload();
      }
    },
  };

  useEffect(() => {
    value.autologin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthsContext.Provider value={value}>
      {children}
    </AuthsContext.Provider>
  );
}

export function useAuths() {
  const context = React.useContext(AuthsContext);
  if (!context) throw new Error('No AuthsContext provider found!');
  return context;
}
