import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import JSZip from 'jszip';
import FileSaver, { saveAs } from 'file-saver';
import { v4 as uuid } from 'uuid';

import axios from 'axios';
import { MCreative } from '../../../modeles';
import {
  ComponentDataClass,
  IData, IGameObjectJson,
} from '../../../modeles/MP_Interfaces';
import {
  deleteGameObjectByID, duplicateGameObjectByID, essenceOfStr, generateAssetId,
  objByArrPath, updateObjectByArrPath,
} from './utils.context';
import { playablesService } from '../../../services';
import { ColorTools } from '../../../tools';

export type OrientationType = 'portrait' | 'landscape';

export interface PlayableContextType {
  playable: MCreative;
  playableJson: IData | null;
  playableWindow: any | null;
  playableClasses: any | null;
  playableThree: any | null
  playableCommands: any | null;
  phoneOrientation: { width: number, height: number };
  playableIframeRef: any;
  isPlayableLoaded: boolean;
  nodeEdit: IGameObjectJson | null;

  getPlayableFile: () => Promise<string>;
  getPhoneOrientation: () => OrientationType;
  getTourVisibility: () => boolean;
  getRunTimeComponent: (path: string) => ComponentDataClass | null;
  getRuntimeGameObject: (instanceId: string) => any
  getNodeEdit: () => IGameObjectJson | undefined;
  getAllOpenKeys: () => string[];
  getCompTypeIndex: (type: string, parentID: string, childID: string) => number | undefined;
  setIsPlayableLoaded: (isLoaded: boolean) => void;
  setPlayable: (playable: MCreative | null) => void;
  setPlayableJson: (data: IData) => void;
  setStockedJson: (data: IData) => void;
  setNodeEdit: (node: IGameObjectJson | null) => void;
  setNodeOpened: (nodes: string[], open?: boolean, closeAll?: boolean) => void;
  setTourVisibility: (visibility: boolean) => void;
  addGameObjectToJson: (newGameObject: any) => void,
  addModelMeshToJson: (threeObj: any) => { name: string, asset: string };
  addModelMaterialsToJson: (threeObj: any) => string[];
  addModelTextureToJson: (texture: any) => void;
  addModelSpriteToJson: (sprite: any) => void;
  updateGameObject: (value: any, propertyPath: string, childID: string, updateJson?: boolean, instanceID?: string,) => void,
  toggleEnabledStateGameObject: (instanceID: string, bool: boolean) => void,
  duplicateMaterial: (materialId: string) => string,
  deleteGameObject: (instanceID: string) => void,
  duplicateGameObject: (newGameObject: any) => void,
  refreshPlayable: () => void;
  play: () => void;
  pause: () => void;
  playableIsPlaying: () => boolean;
  exportJson: () => void;
  tryPlayable: () => void;
  downloadPlayable: () => void;
  getTryQrcode: () => Promise<string>;
}

const PlayableContext = React.createContext<PlayableContextType>({
  playable: new MCreative(),
  playableJson: null,
  playableWindow: null,
  playableClasses: null,
  playableThree: null,
  playableCommands: null,
  phoneOrientation: { width: 360, height: 640 },
  playableIframeRef: null,
  isPlayableLoaded: false,
  nodeEdit: null,

  getPhoneOrientation: () => { return 'portrait'; },
  getTourVisibility: () => { return false; },
  getNodeEdit: () => { return undefined; },
  getRuntimeGameObject: () => { return null; },
  getRunTimeComponent: () => { return null; },
  // eslint-disable-next-line @typescript-eslint/require-await
  getPlayableFile: async () => { return ''; },
  getAllOpenKeys: () => { return []; },
  getCompTypeIndex: (/* type: string, parentID: string, childID: string */) => { return undefined; },
  setIsPlayableLoaded: (/* isLoaded: boolean */) => { },
  setPlayable: (/* playable: MCreative | null */) => { },
  setPlayableJson: (/* playableJson: Object */) => { },
  setStockedJson: (/* playableJson: Object */) => { },
  setNodeEdit: (/* playableJson: Object */) => { },
  setNodeOpened: (/* playableJson: Object */) => { return []; },
  setTourVisibility: () => { },
  addGameObjectToJson: () => { },
  addModelMaterialsToJson: () => { return ['']; },
  addModelMeshToJson: () => { return { name: '', asset: '' }; },
  addModelTextureToJson: () => { },
  addModelSpriteToJson: () => { },
  updateGameObject: () => { },
  toggleEnabledStateGameObject: () => { },
  duplicateMaterial: () => { return ''; },
  deleteGameObject: () => { },
  duplicateGameObject: () => { },
  refreshPlayable: () => { },
  play: () => { },
  pause: () => { },
  playableIsPlaying: () => { return false; },
  exportJson: () => { },
  tryPlayable: () => { },
  downloadPlayable: () => { },
  // eslint-disable-next-line @typescript-eslint/require-await
  getTryQrcode: async () => { return ''; },
});

export function PlayableContextProvider({ children }: { children: React.ReactNode }) {
  const [playable, _setPlayable] = useState<MCreative>(new MCreative());
  const [playableJson, _setPlayableJson] = useState<IData | null | any>(null);
  const [stockedJson, _setStockedJson] = useState<IData | null | any>(null);
  const [playableClasses, _setPlayableClasses] = useState<any | null>(null);
  const [playableThree, _setPlayableThree] = useState<any | null>(null);
  const [playableWindow, _setPlayableWindow] = useState<any | null>(null);
  const [playableCommands, _setPlayableCommands] = useState<any | null>(null);
  const [nodeEdit, _setNodeEdit] = useState<IGameObjectJson | null>(null);
  const [nodeOpened, _setNodeOpened] = useState<string[]>([]);
  const [phoneOrientation] = useState<{ width: number, height: number }>({ width: 360, height: 640 });
  const [tourIsVisible, _setTourIsVisible] = useState<boolean>(true);
  const [isPlayableLoaded, _setIsPlayableLoaded] = useState<boolean>(false);

  const playableIframeRef = useRef<HTMLIFrameElement>(null);
  const itRef = useRef<NodeJS.Timeout | null>(null);

  const setMonsterPlayableData = useCallback(() => {
    itRef.current = setInterval(() => {
      if (playableIframeRef?.current) {
        const window = playableIframeRef.current!.contentWindow;
        // @ts-ignore
        if (window?.monsterplayable) {
          const {
            classes,
            public: mpPublic,
            three,
            commands,
            // @ts-ignore
          } = window.monsterplayable;

          if (window && classes && mpPublic?.data && three && commands) {
            clearInterval(itRef.current as NodeJS.Timeout);
            _setPlayableWindow(window);
            _setPlayableClasses(classes);
            _setPlayableJson(mpPublic.data as IData);
            _setPlayableThree(three);
            _setPlayableCommands(commands);
          }
        }
      }
    }, 250);
  }, []);

  useEffect(() => {
    if (playableIframeRef.current && isPlayableLoaded) {
      setMonsterPlayableData();
    }
  }, [isPlayableLoaded, setMonsterPlayableData]);

  const getPhoneOrientation = useCallback((): OrientationType => {
    if (playable.iecData._phoneWidth > playable.iecData._phoneHeight) {
      return 'landscape';
    }
    return 'portrait';
  }, [playable]);

  const getPlayableFile = useCallback(async (): Promise<string> => {
    let file = await (await fetch(playable.Playable?.url || '')).text() as string;
    if (stockedJson) {
      file = file.replace('var JSON_DATA = ""', `var JSON_DATA = '${JSON.stringify(stockedJson)}'`);
    }
    return file;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playable]);

  const getTourVisibility = useCallback((): boolean => {
    return tourIsVisible;
  }, [tourIsVisible]);

  const getRuntimeGameObject = useCallback((instanceID: string): any => {
    if (instanceID !== undefined) {
      return playableClasses?.MP_GameManager.scene.gameObjects.find((gameObject: IGameObjectJson) => gameObject.instanceID === instanceID);
    }
    return undefined;
  }, [playableClasses?.MP_GameManager?.scene?.gameObjects]);

  const getRunTimeComponent = useCallback((path: string) => {
    const mandatoryPath = 'mp_components';
    let propertyParts = `${mandatoryPath}.${path}`.split('.');
    let target = getRuntimeGameObject(nodeEdit?.instanceID as string);

    if (propertyParts.length === 3) {
      propertyParts = [mandatoryPath, `${propertyParts[1]}.${propertyParts[2]}`];
    }

    try {
      for (let i = 0; i < propertyParts.length; i += 1) {
        const propertyPart = propertyParts[i];
        if (propertyPart.includes('[')) {
          const regex = /(.+)\[(\d+)\]/;
          const match = propertyPart.match(regex);

          target = target[match![1]][match![2]];
        } else {
          target = target[propertyPart];
        }
      }
    } catch (error) {
      console.error(error);
    }

    return target as ComponentDataClass;
  }, [getRuntimeGameObject, nodeEdit]);

  const getNodeEdit = useCallback(() => {
    if (nodeEdit) {
      // console.log('nodeEdit: ', nodeEdit);
    }
    return nodeEdit || undefined;
  }, [nodeEdit]);

  const getAllOpenKeys = useCallback(() => {
    return nodeOpened;
  }, [nodeOpened]);

  const setPlayable = useCallback((data: MCreative | null) => {
    _setIsPlayableLoaded(false);
    _setPlayableJson(null);
    _setNodeEdit(null);
    if (!data || playable.id !== data.id) {
      _setStockedJson(null);
    }
    _setPlayable(JSON.parse(JSON.stringify(data || new MCreative())) as MCreative);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setPlayableJson = useCallback((data: IData | any) => {
    _setPlayableJson(JSON.parse(JSON.stringify(data)));
  }, []);

  const setStockedJson = useCallback((data: IData | any) => {
    _setStockedJson(JSON.parse(JSON.stringify(data)));
  }, []);

  const refreshPlayable = useCallback(() => {
    _setNodeEdit(null);
    _setIsPlayableLoaded(false);
    _setPlayable(JSON.parse(JSON.stringify(playable)) as MCreative);
    if (stockedJson) {
      setPlayableJson(stockedJson);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playable, stockedJson]);

  const setTourVisibility = useCallback((visibility: boolean) => {
    _setTourIsVisible(visibility);
  }, [_setTourIsVisible]);

  const setIsPlayableLoaded = useCallback((isLoaded: boolean) => {
    _setIsPlayableLoaded(isLoaded);
  }, [_setIsPlayableLoaded]);

  const setNodeEdit = useCallback((node: IGameObjectJson | null) => {
    _setNodeEdit(node);
  }, [_setNodeEdit]);

  const setNodeOpened = useCallback((nodes: string[], open: boolean = true, closeAll?: boolean) => {
    if (closeAll) {
      _setNodeOpened(nodes);
    } else {
      let fullNodes = Array.from(new Set(getAllOpenKeys().concat(nodes)));
      if (!open) {
        fullNodes = fullNodes.filter((node) => !nodes.includes(node));
      }
      _setNodeOpened(fullNodes);
    }
  }, [getAllOpenKeys]);

  const updateJsonData = useCallback(async (gameObject: any, componentPath: any, value: any, childID: string) => {
    let currGameObject = { ...gameObject };

    const retroFields: any = [];
    while (currGameObject?.mp_parent !== null) {
      retroFields.unshift(currGameObject.instanceID);
      currGameObject = currGameObject?.mp_parent;
    }
    retroFields.unshift(currGameObject.instanceID);

    const arrPath: any = ['scene', 'gameObjects'];
    for (let i = 0; i < retroFields.length; i += 1) {
      const field: string = retroFields[i];
      const dataObj = objByArrPath(playableJson, arrPath);
      const foundGameObject: IGameObjectJson = dataObj?.find((e: any) => e?.instanceID === field);

      if (foundGameObject !== undefined) {
        const index = dataObj.indexOf(foundGameObject);

        arrPath.push(index);
        if (retroFields[i + 1] !== undefined) {
          arrPath.push('children');
        }
      }
    }
    arrPath.push('components');

    const compIndex = objByArrPath(playableJson, arrPath)?.findIndex((e: any) => e?.instanceID === childID);

    if (nodeEdit?.components[compIndex].type === 'Script') {
      arrPath.push(compIndex, 'mp_variables');

      const copyComponentPath = [...componentPath];
      copyComponentPath.splice(0, 3);

      // @ts-ignore
      let lastIndex = nodeEdit?.components[compIndex].mp_variables.findIndex((v) => v.name === componentPath[2]);
      arrPath.push(lastIndex);
      // @ts-ignore
      const lastNode = nodeEdit?.components[compIndex].mp_variables[lastIndex];

      copyComponentPath.forEach((node) => {
        if (copyComponentPath.length > 0) {
          lastIndex = lastNode.value.findIndex((n: any) => n.name === node);
          arrPath.push('value', lastIndex);
        } else {
          // console.log('NOTHING: ');
        }
      });

      arrPath.push('value');
      if (value.isColor) {
        setPlayableJson(
          updateObjectByArrPath(
            playableJson,
            arrPath,
            `${value.r}|||${value.g}|||${value.b}|||${value.a}`,
          ),
        );
      } else if (Array.isArray(value)) {
        const newValueList = value.map((a) => a.toString() as string);
        setPlayableJson(updateObjectByArrPath(playableJson, arrPath, newValueList));
      } else {
        setPlayableJson(updateObjectByArrPath(playableJson, arrPath, value.toString()));
      }

      return;
    }

    // Checks if material changes
    const lastSplit = componentPath[2]?.split('[');

    if (lastSplit?.length > 1) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      arrPath.push(compIndex, lastSplit[0], parseInt(lastSplit[1], 10));
      const assetsPath = ['assets', 'materials', objByArrPath(playableJson, arrPath)];
      const fieldName = componentPath[componentPath.length - 1];

      // If material color has transparency changes
      if (value.isColor) {
        setPlayableJson(updateObjectByArrPath(playableJson, [...assetsPath, 'opacity'], value?.a));
        setPlayableJson(updateObjectByArrPath(playableJson, [...assetsPath, 'transparent'], value?.a !== 1));
        setPlayableJson(updateObjectByArrPath(playableJson, [...assetsPath, fieldName], ColorTools.mToRgba(value)));
        return;
      }
      if (fieldName === 'mainTextureAssetName') {
        const texturesPath = ['assets', 'textures'];
        const newTextureId = generateAssetId(objByArrPath(playableJson, texturesPath));
        texturesPath.push(newTextureId);

        const zip = new JSZip();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const res = await fetch(value.data);
        const resBlob = await res.blob();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        zip.file(value.name, resBlob);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        zip.generateAsync({ type: 'blob' }).then((blob: any) => { saveAs(blob, 'images.zip'); });
        setPlayableJson(updateObjectByArrPath(playableJson, texturesPath, { texturePath: `Assets/CustomImages/${value.name}` }));
        setPlayableJson(updateObjectByArrPath(playableJson, [...assetsPath, 'mainTextureAssetName'], newTextureId));
        return;
      }
      setPlayableJson(updateObjectByArrPath(playableJson, [...assetsPath, fieldName], value));
      return;
    }

    arrPath.push(compIndex, ...componentPath.slice(2));
    const lastPath = arrPath[arrPath.length - 1];

    // Checking if changes concerns rotation
    if (lastPath === 'localEulerAngles') {
      const newQuaternion = playableClasses.Quaternion.MP_FromEuler(value.x, value.y, value.z);

      arrPath[arrPath.length - 1] = 'localRotation';
      setPlayableJson(updateObjectByArrPath(playableJson, arrPath, newQuaternion.toJSON()));
      return;
    }
    if (lastPath === 'sharedMesh_Infos') {
      const sharedValue = {
        name: value.name,
        asset: value.modelIndex,
        type: 'Mesh',
      };
      setPlayableJson(updateObjectByArrPath(playableJson, arrPath, sharedValue));
      return;
    }
    if (value.isColor) {
      setPlayableJson(updateObjectByArrPath(playableJson, arrPath, value?.a));
      setPlayableJson(updateObjectByArrPath(playableJson, arrPath, value?.a !== 1));
      setPlayableJson(updateObjectByArrPath(playableJson, arrPath, ColorTools.mToRgba(value)));
      return;
    }
    // Regular update
    setPlayableJson(updateObjectByArrPath(playableJson, arrPath, value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playableJson, nodeEdit]);

  const addGameObjectToJson = useCallback((newGameObject: any) => {
    const newGameObjects = JSON.parse(JSON.stringify(playableJson)).scene.gameObjects;

    newGameObjects.push(newGameObject);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    _setPlayableJson((old: any) => ({ ...old, scene: { ...old.scene, gameObjects: newGameObjects } }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playableJson]);

  const addModelMeshToJson = useCallback((threeObj: any): { name: string, asset: string } => {
    const newModels = JSON.parse(JSON.stringify(playableJson)).assets.models;
    const modelName = `${uuid().substring(0, 6)}/${threeObj.name}`;
    const modelAsset = uuid().substring(0, 6);
    const { normal: n, position: v, uv } = threeObj.geometry.attributes;

    newModels[modelAsset] = {};
    // eslint-disable-next-line react/forbid-prop-types
    newModels[modelAsset][modelName] = { n: n.array, v: v.array, uv: uv.array };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    _setPlayableJson((old: any) => ({ ...old, assets: { ...old.assets, models: newModels } }));

    // return { name: '544/Cube', asset: 'PrimitiveCube' }; // TMP will always work
    return { name: modelName, asset: modelAsset };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playableJson]);

  const addModelMaterialsToJson = useCallback((threeObj: any): string[] => {
    const newMaterials = JSON.parse(JSON.stringify(playableJson)).assets.materials;
    const newMaterialsIds: string[] = [];

    threeObj.material.forEach((threeMaterial: any) => {
      const materialId = uuid().substring(0, 6);
      newMaterialsIds.push(materialId);

      newMaterials[materialId] = {
        shader: 'Standard',
        name: threeMaterial.name ?? 'material',
        emissionFlag: 1,
        glossiness: 0.5,
        glossMapScale: 1.0,
        cutoff: 0.5,
        color: {
          r: threeMaterial?.color?.r ?? 1,
          g: threeMaterial?.color?.g ?? 1,
          b: threeMaterial?.color?.b ?? 1,
          a: 1.0,
          type: 'Color',
        },
        type: 'Material',
      };
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    _setPlayableJson((old: any) => ({ ...old, assets: { ...old.assets, materials: newMaterials } }));

    return newMaterialsIds;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playableJson]);

  const addModelTextureToJson = useCallback((texture: any) => {
    const newTextures = JSON.parse(JSON.stringify(playableJson)).assets.textures;

    newTextures[texture.instanceID] = {
      texturePath: texture.mp_img64,
      name: texture.name,
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    _setPlayableJson((old: any) => ({ ...old, assets: { ...old.assets, textures: newTextures } }));
  }, [playableJson]);

  const addModelSpriteToJson = useCallback((sprite: any) => {
    const newSprites = JSON.parse(JSON.stringify(playableJson)).assets.sprites;

    newSprites[sprite.instanceID] = playableClasses.SpriteToJsonObject(sprite);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    _setPlayableJson((old: any) => ({ ...old, assets: { ...old.assets, sprites: newSprites } }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playableJson]);

  const updateGameObject = useCallback((
    value: any,
    propertyPath: string,
    childID: string,
    updateJson: boolean = true,
    instanceID?: string, // From 'Scene' only
  ) => {
    const mandatoryPath = 'mp_components/';
    const propertyParts = (mandatoryPath + propertyPath).split('/');

    if (nodeEdit?.instanceID !== undefined || instanceID !== undefined) {
      let target = getRuntimeGameObject(nodeEdit?.instanceID as string ?? instanceID);

      if (target !== undefined) {
        if (updateJson && !playableIsPlaying()) {
          updateJsonData(
            target,
            propertyParts,
            (value.toJSON === undefined) || !!value.isColor ? value : value.toJSON(),
            childID,
          );
        }

        for (let i = 0; i < propertyParts.length - 1; i += 1) {
          const propertyPart = propertyParts[i];

          if (propertyPart.includes('[')) {
            const regex = /(.+)\[(\d+)\]/;
            const match = propertyPart.match(regex);

            target = target[match![1]][match![2]];
          } else {
            target = target[propertyPart];
          }
        }
        if (propertyPath !== 'MeshRenderer[0]/sharedMaterials') {
          target[propertyParts[propertyParts.length - 1]] = value;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRuntimeGameObject, nodeEdit?.instanceID, updateJsonData]);

  const toggleEnabledStateGameObject = useCallback((instanceID: string, bool: boolean) => {
    let gameObject = getRuntimeGameObject(instanceID);

    gameObject.activeSelf = bool;

    const retroFields: any = [];
    while (gameObject?.mp_parent !== null) {
      retroFields.unshift(gameObject?.instanceID);
      gameObject = gameObject?.mp_parent;
    }
    retroFields.unshift(gameObject?.instanceID);

    // Building the children path array
    const arrPath: any = ['scene', 'gameObjects'];
    for (let i = 0; i < retroFields.length; i += 1) {
      const field: string = retroFields[i];
      const dataObj = objByArrPath(playableJson, arrPath);
      const foundGameObject: IGameObjectJson = dataObj?.find((e: any) => e?.instanceID === field);

      if (foundGameObject !== undefined) {
        const index = dataObj.indexOf(foundGameObject);

        arrPath.push(index);
        if (retroFields[i + 1] !== undefined) { arrPath.push('children'); }
      }
    }

    setPlayableJson(updateObjectByArrPath(playableJson, [...arrPath, 'activeSelf'], bool));
  }, [getRuntimeGameObject, playableJson, setPlayableJson]);

  const duplicateMaterial = useCallback((materialId: string): string => {
    // Json
    const materialToDup = playableJson.assets.materials[materialId];
    const materialsPath: string[] = ['assets', 'materials'];
    const newMaterialName = essenceOfStr(materialToDup.name as string);
    let dupNb = 0;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    Object.values(objByArrPath(playableJson, materialsPath))?.forEach((material: any) => {
      if (essenceOfStr(newMaterialName) === essenceOfStr(material.name as string)) { dupNb += 1; }
    });

    const newMaterialId = generateAssetId(objByArrPath(playableJson, materialsPath));
    materialsPath.push(newMaterialId);

    setPlayableJson(updateObjectByArrPath(playableJson, materialsPath, {
      ...materialToDup,
      name: `${newMaterialName} ${dupNb}`,
    }));

    // Runtime
    const target = getRuntimeGameObject(nodeEdit?.instanceID as string);
    let mat = new playableClasses.Material();

    mat = target.mp_components.MeshRenderer[0].materials[0];
    target.mp_components.MeshRenderer[0].materials = [mat];

    return newMaterialId;
  }, [getRuntimeGameObject, nodeEdit?.instanceID, playableClasses?.Material, playableJson, setPlayableJson]);

  const deleteGameObject = useCallback((instanceID: string) => {
    const newJson = { ...playableJson };

    deleteGameObjectByID(newJson.scene.gameObjects, instanceID);

    setPlayableJson(newJson);
  }, [playableJson, setPlayableJson]);

  const duplicateGameObject = useCallback((newGameObject: any) => {
    let objects = { ...playableJson };
    objects = objects.scene.gameObjects;
    let currGameObject = { ...newGameObject };

    const retroFields: any = [];
    const retroFinals: any = [];

    while (currGameObject?.mp_parent !== null) {
      retroFields.unshift(currGameObject.instanceID);
      currGameObject = currGameObject?.mp_parent;
    }
    retroFields.unshift(currGameObject.instanceID);

    retroFields.forEach((retroID: string) => {
      const objectIndex = objects.findIndex((obj: any) => obj.instanceID === retroID);

      if (objectIndex !== -1) {
        retroFinals.push(objectIndex, 'children');
      }
      if (objects[objectIndex]?.children !== undefined) {
        objects = objects[objectIndex].children;
      }
    });

    duplicateGameObjectByID(objects, newGameObject);

    const newObjects = { ...playableJson };

    if (retroFinals?.length === 0) {
      newObjects.scene.gameObjects = objects;
    } else {
      let current = newObjects.scene.gameObjects;
      for (let i = 0; i < retroFinals.length - 1; i += 1) {
        current = current[retroFinals[i]];
      }
      current[retroFinals[retroFinals.length - 1]] = objects;
    }
    setNodeEdit(null);
    setPlayableJson(newObjects);
  }, [playableJson, setNodeEdit, setPlayableJson]);

  const getCompTypeIndex = useCallback((type: string, parentID: string, childID: string): number | undefined => {
    const runtimeGameObject = getRuntimeGameObject(parentID);
    try {
      if (runtimeGameObject) {
        const mpComponents = runtimeGameObject.mp_components;
        const index: number = mpComponents[type].findIndex((comp: any) => comp.instanceID === childID);

        return index === -1 ? undefined : index;
      }
    } catch (error) {
      return undefined;
    }
    return undefined;
  }, [getRuntimeGameObject]);

  const play = useCallback(() => {
    setStockedJson(playableJson);
    playableClasses?.MP_GameManager.play();
  }, [playableClasses?.MP_GameManager, playableJson, setStockedJson]);

  const pause = useCallback(() => {
    playableClasses?.MP_GameManager.pause();
  }, [playableClasses?.MP_GameManager]);

  const playableIsPlaying = useCallback(() => {
    return playableClasses?.MP_GameManager?.isPlaying as boolean;
  }, [playableClasses]);

  const exportJson = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(playableJson))}`;
    const link = document.createElement('a');

    link.href = jsonString;
    link.download = 'data.json';
    link.click();
    link.remove();
  }, [playableJson]);

  const getBlobPlayable = useCallback(async (url: string): Promise<Blob> => {
    const response = await axios.get(url, { responseType: 'blob' });
    const text = await new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event?.target?.result) {
          resolve(event.target.result as string);
        } else {
          reject(new Error('Failed to read file'));
        }
      };
      reader.readAsText(response.data as Blob);
    });

    const modifiedText = text.replace('var JSON_DATA = ""', `var JSON_DATA = '${JSON.stringify(playableJson)}'`);
    const loggerScript = `
        <script>
            window.addEventListener("load", (event) => {
              playPlayable();
            });

            function playPlayable() {
                setTimeout(() => {
                  if (monsterplayable && monsterplayable.commands && monsterplayable.commands.play) {
                    monsterplayable.commands.play();
                  } else {
                    playPlayable();
                  }
                }, 100);
            }
        </script>
    `;
    const modifiedPlayableFile = modifiedText.replace('</head>', `${loggerScript}</head>`);
    return new Blob([modifiedPlayableFile], { type: 'text/html' }) as Blob;
  }, [playableJson]);

  const tryPlayable = useCallback(async () => {
    if (playable.Playable?.url) {
      const newBlob = await getBlobPlayable(playable.Playable?.url);
      const blobURL = URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.href = blobURL;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }, [getBlobPlayable, playable.Playable?.url]);

  const downloadPlayable = useCallback(async () => {
    if (playable.Playable?.url) {
      const newBlob = await getBlobPlayable(playable.Playable?.url);
      FileSaver.saveAs(newBlob as Blob, 'TEST.html');
    }
  }, [getBlobPlayable, playable.Playable?.url]);

  const getTryQrcode = useCallback(async (): Promise<string> => {
    if (playable.Playable?.url) {
      const newBlob = await getBlobPlayable(playable.Playable?.url);
      const data = await playablesService.playableGenerateQrcode(playable.id, newBlob);
      return data.url as string || '';
    }
    return '';
  }, [getBlobPlayable, playable.Playable?.url, playable.id]);

  const value = useMemo(() => ({
    playableIframeRef,
    playable,
    playableJson,
    playableClasses,
    playableWindow,
    playableThree,
    playableCommands,
    isPlayableLoaded,
    nodeEdit,
    phoneOrientation,

    getPlayableFile,
    getPhoneOrientation,
    getTourVisibility,
    getRuntimeGameObject,
    getRunTimeComponent,
    getNodeEdit,
    getAllOpenKeys,
    getCompTypeIndex,
    setIsPlayableLoaded,
    setPlayable,
    setPlayableJson,
    setStockedJson,
    setNodeEdit,
    setNodeOpened,
    setTourVisibility,
    refreshPlayable,
    updateGameObject,
    addGameObjectToJson,
    addModelMaterialsToJson,
    addModelMeshToJson,
    addModelTextureToJson,
    addModelSpriteToJson,
    toggleEnabledStateGameObject,
    duplicateMaterial,
    deleteGameObject,
    duplicateGameObject,
    play,
    pause,
    playableIsPlaying,
    exportJson,
    tryPlayable,
    downloadPlayable,
    getTryQrcode,
  }), [
    playableIframeRef,
    playable,
    playableJson,
    playableClasses,
    playableThree,
    playableWindow,
    playableCommands,
    isPlayableLoaded,
    phoneOrientation,
    nodeEdit,

    getPlayableFile,
    getPhoneOrientation,
    getTourVisibility,
    getNodeEdit,
    getRuntimeGameObject,
    getRunTimeComponent,
    getAllOpenKeys,
    getCompTypeIndex,
    setIsPlayableLoaded,
    setPlayable,
    setPlayableJson,
    setStockedJson,
    setNodeEdit,
    setNodeOpened,
    setTourVisibility,
    refreshPlayable,
    addGameObjectToJson,
    addModelMaterialsToJson,
    addModelMeshToJson,
    addModelTextureToJson,
    addModelSpriteToJson,
    updateGameObject,
    toggleEnabledStateGameObject,
    duplicateMaterial,
    deleteGameObject,
    duplicateGameObject,
    play,
    pause,
    playableIsPlaying,
    exportJson,
    tryPlayable,
    downloadPlayable,
    getTryQrcode,
  ]);

  return (
    <PlayableContext.Provider value={value}>
      {children}
    </PlayableContext.Provider>
  );
}

export function usePlayable() {
  const context = React.useContext(PlayableContext);
  if (!context) throw new Error('No PlayableContext provider found!');
  return context;
}
