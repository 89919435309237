import {
  Button, Dropdown, Form, Input, MenuProps, Modal,
  Popconfirm, Select, Tooltip, UploadProps, message,
} from 'antd';
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Dragger from 'antd/es/upload/Dragger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGripDots, faUp, faFileZip, faTrash, faFileExport, faBurst,
} from '@fortawesome/pro-regular-svg-icons';
import { InboxOutlined } from '@ant-design/icons';

import { useAuths } from '../../../../../../contexts';
import { CreativesContextType, useCreatives } from '../../../../context/creatives.context';
import { MCreative, MPlayableEngine } from '../../../../../../modeles';
import { antFormNormFile } from '../../../../../../tools';
import { creativesService, playableEngineService, playablesService } from '../../../../../../services';

export function PlayablesListActions(props: {
  record: MCreative,
}) {
  const uAuths = useAuths();
  const uCreatives: CreativesContextType = useCreatives();
  const uParams: any = useParams();
  const uHistory = useHistory();

  const [isImportPlayableData, _setIsImportPlayableData] = useState<number | null>(null);
  const [showUpdateEngineVersion, _setShowUpdateEngineVersion] = useState<boolean>(false);
  const [playableDataId, _setPlayableDataId] = useState<number>();
  const [versions, _setVersions] = useState<MPlayableEngine[]>([]);

  useEffect(() => {
    playableEngineService.compatiblesEngines(props.record.playableEngineVersion).then((engines) => {
      _setVersions(engines || []);
    });
  }, [props.record.playableEngineVersion]);

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        !uAuths.isFreeAccount()
          ? (
            <Link className="export" to={`/applications/${uParams.appId}/export/${props.record.id}`}>
              <Button
                type="link"
                icon={<FontAwesomeIcon icon={faFileExport} />}
                size="small"
              >
                Export Playable
              </Button>
            </Link>
          )
          : (
            <Tooltip title="Your account is on FREE mode, contact us at: contact@monsterplayable.com!" placement="bottomLeft">
              <Button
                type="link"
                disabled={uAuths.isFreeAccount()}
                icon={<FontAwesomeIcon icon={faFileExport} />}
                size="small"
              >
                Export Playable
              </Button>
            </Tooltip>
          )
      ),
    },
    {
      key: '2',
      label: (
        <Button
          type="link"
          icon={<FontAwesomeIcon icon={faUp} />}
          size="small"
          onClick={() => _setShowUpdateEngineVersion(true)}
        >
          Update Engine version
        </Button>
      ),
    },
    {
      key: '3',
      label: (
        uAuths.isAdmin()
        && (
          <Popconfirm zIndex={3} title="Sure to delete?" onConfirm={() => { uCreatives.adminDelete(props.record.id); }}>
            <Button type="link" icon={<FontAwesomeIcon icon={faTrash} />} size="small" danger>Delete Creative</Button>
          </Popconfirm>
        )
      ),
    },
    {
      key: '4',
      label: (
        uAuths.isSuperAdmin()
        && (
          <Popconfirm title="Sure to delete?" onConfirm={() => { uCreatives.superAdminDelete(props.record.id); }}>
            <Button type="dashed" icon={<FontAwesomeIcon icon={faBurst} />} size="small" danger>Delete (Sup.Admin)</Button>
          </Popconfirm>
        )
      ),
    },
    {
      key: '5',
      label: (
        uAuths.isSuperAdmin() && (
          <Button
            type="dashed"
            icon={<FontAwesomeIcon icon={faFileZip} />}
            size="small"
            danger
            onClick={() => _setIsImportPlayableData(props.record.id)}
          >
            Import ZIP (Sup.Admin)
          </Button>
        )
      ),
    },
  ];

  const onImportPlayableData = (values: MCreative) => {
    if (playableDataId && isImportPlayableData) {
      creativesService.view(isImportPlayableData as number).then((creative) => {
        const newPlayable = {
          ...creative,
          playableEngineVersion: values.playableEngineVersion,
          playableDataId,
        } as MCreative;

        creativesService.edit(isImportPlayableData, newPlayable).then((data) => {
          if (data) {
            uHistory.push(`/applications/${uParams.appId}/creatives/playable/${data.id}`);
          }
        });
      });
    }
  };

  const onChangeEngineVersion = (values: any) => {
    playablesService.iteratePlayable(props.record.id, null, values.engineVersion as string)
      .then((data: any) => {
        _setShowUpdateEngineVersion(false);
      });
  };

  const draggerParamsPlayableData: UploadProps = {
    name: 'playableData',
    multiple: false,
    action: `${process.env.REACT_APP_API_URL}/creatives/import-playable-data`,
    maxCount: 1,
    headers: {
      authorization: localStorage.getItem('user-token') || '',
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        message.success(`${info.file.name} Playable Data uploading.`);
      }
      if (status === 'done') {
        message.success(`${info.file.name} Playable Data uploaded successfully.`);
        _setPlayableDataId(info.file.response.data.id as number);
      } else if (status === 'error') {
        message.error(`${info.file.name} Playable Data upload failed.`);
      }
    },
  };

  return (
    <>
      <Dropdown menu={{ items }} placement="bottomLeft" trigger={['click']}>
        <Button icon={<FontAwesomeIcon icon={faGripDots} />} />
      </Dropdown>

      {
        // #region MODAL - IMPORT ZIP DATA
      }
      <Modal
        width="720px"
        title="Import Playable Data"
        open={!!isImportPlayableData}
        okText={<>Validate</>}
        okButtonProps={{ form: 'formCreate', htmlType: 'submit' }}
        onCancel={() => {
          _setIsImportPlayableData(null);
        }}
      >
        <Form id="formCreate" onFinish={onImportPlayableData} layout="vertical">
          <div className="row">
            <Form.Item
              className="col-6"
              label="Engine version"
              name="engineVersion"
              rules={[{ required: true }]}
              initialValue={versions[versions.length - 1]?.version || props.record.playableEngineVersion}
            >
              {
                versions.length > 0
                  ? (
                    <Select
                      options={
                        versions.map((version) => ({ value: version.version, label: version.version }))
                      }
                    />
                  )
                  : (
                    <Input type="text" disabled />
                  )
              }

            </Form.Item>
            <Form.Item
              className="col-12"
              label="Playable Data"
              name="playableData"
              rules={[{ required: true }]}
              valuePropName="fileList"
              getValueFromEvent={antFormNormFile}
            >
              <Dragger {...draggerParamsPlayableData}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag a Zip to this area to upload</p>
                <p className="ant-upload-hint">
                  Upload a single Zip
                </p>
              </Dragger>
            </Form.Item>
          </div>
        </Form>
      </Modal>
      {
        // #endregion MODAL - IMPORT ZIP DATA
      }

      {
        // #region MODAL - UPDATE ENGINE VERSION
      }
      <Modal
        width="720px"
        title="Update engine version"
        open={!!showUpdateEngineVersion}
        okText={<>Validate</>}
        okButtonProps={{ form: `formCreate-${props.record.id}`, htmlType: 'submit' }}
        onCancel={() => {
          _setShowUpdateEngineVersion(false);
        }}
      >
        <Form id={`formCreate-${props.record.id}`} onFinish={onChangeEngineVersion} layout="vertical">
          <div className="row">
            <Form.Item
              className="col-6"
              label="Current Engine version"
              name="playableEngineVersion"
              rules={[{ required: false }]}
              initialValue={props.record.playableEngineVersion}
            >
              <Input type="text" disabled />
            </Form.Item>
            <Form.Item
              className="col-6"
              label="New Engine version"
              name="engineVersion"
              rules={[{ required: true }]}
              initialValue={versions[versions.length - 1]?.version || props.record.playableEngineVersion}
            >
              {
                versions.length > 0
                  ? (
                    <Select
                      options={
                        versions.map((version) => ({ value: version.version, label: version.version }))
                      }
                    />
                  )
                  : (
                    <Input type="text" disabled />
                  )
              }

            </Form.Item>
          </div>
        </Form>
      </Modal>
      {
        // #endregion MODAL - UPDATE ENGINE VERSION
      }
    </>
  );
}
