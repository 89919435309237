import { BaseService } from './base.service';
import { MMppSdk, MTag } from '../modeles';

class MpSdkService extends BaseService {
  PATH = 'mp-sdk/';

  async listing(): Promise<MMppSdk[] | null> {
    return this._listing(`${this.PATH}listing`);
  }

  async add(sdk: MMppSdk): Promise<MMppSdk | null> {
    return this._add(`${this.PATH}add`, sdk);
  }

  async view(id: number): Promise<MMppSdk | null> {
    return this._view(`${this.PATH}view/${id}`);
  }

  async lastSdk(): Promise<MMppSdk | null> {
    return this._view(`${this.PATH}last-sdk`);
  }

  async edit(id: number, sdk: MMppSdk): Promise<MMppSdk | null> {
    return this._edit(`${this.PATH}edit/${id}`, sdk);
  }

  async remove(id: number): Promise<MMppSdk | null> {
    return this._delete(`${this.PATH}delete/${id}`);
  }

  async tags(): Promise<MTag[] | null> {
    return this._view(`${this.PATH}tags`).then((res: any) => res.tags as MTag[]);
  }

  async isRelease(id: number, isRelease: boolean): Promise<MMppSdk[]> {
    return await this._view(`${this.PATH}is-release/${id}/${isRelease}`) || [];
  }
}

export const mpSdkService = new MpSdkService();
