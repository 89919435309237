import { AndroidFilled, AppleFilled } from '@ant-design/icons';
import { Spin } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { MpTable } from '../../../../components';
import { MCreative } from '../../../../modeles';
import { creativesService } from '../../../../services';
import { getUniqueList } from '../../../../tools';

import './creatives.page.scss';

export function CreativesPage() {
  const [loading, _setLoading] = useState(false);
  const [creatives, _setCreatives] = useState<MCreative[]>([]);

  useEffect(() => {
    _setLoading(true);
    creativesService.listingAll().then((data) => {
      _setCreatives(data || []);
      _setLoading(false);
    });
  }, []);

  const columns: ColumnType<MCreative>[] = [
    {
      title: 'Id',
      key: 'id',
      dataIndex: 'id',
      render: (text: string, record: MCreative) => (
        <label>{record.id}</label>
      ),
      sorter: {
        compare: (a: MCreative, b: MCreative) => a.id - b.id,
        multiple: 1,
      },
      filters: getUniqueList(creatives, 'accountId').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MCreative) => record.accountId === (value as number) || false,
      filterSearch: true,
    },
    {
      title: 'Account Id',
      key: 'accountId',
      dataIndex: 'accountId',
      render: (text: string, record: MCreative) => (
        <Link to={`/admin/accounts/account/${record.accountId}`}>
          #
          {record.accountId}
        </Link>
      ),
      sorter: {
        compare: (a: MCreative, b: MCreative) => a.accountId - b.accountId,
        multiple: 2,
      },
      filters: getUniqueList(creatives, 'accountId').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MCreative) => record.accountId === (value as number) || false,
      filterSearch: true,
    },
    {
      title: 'Account',
      key: 'account',
      dataIndex: 'account',
      render: (text: string, record: MCreative) => (
        <Link to={`/admin/accounts/account/${record.accountId}`}>
          {record.Account?.name}
        </Link>
      ),
      sorter: (a: MCreative, b: MCreative) => a.Account?.name.localeCompare(b.Account?.name || '') || -1,
      filters: getUniqueList(creatives, 'Account.name').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MCreative) => record.Account?.name === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'User Id',
      key: 'userId',
      dataIndex: 'userId',
      render: (text: string, record: MCreative) => (
        <label>{record.userId}</label>
      ),
      sorter: {
        compare: (a: MCreative, b: MCreative) => a.userId - b.userId,
        multiple: 3,
      },
      filters: getUniqueList(creatives, 'userId').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MCreative) => record.userId === (value as number) || false,
      filterSearch: true,
    },
    {
      title: 'User',
      key: 'user',
      dataIndex: 'user',
      render: (text: string, record: MCreative) => (
        <label>{record.User?.name}</label>
      ),
      sorter: (a: MCreative, b: MCreative) => a.User?.name.localeCompare(b.User?.name || '') || -1,
      filters: getUniqueList(creatives, 'User.name').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MCreative) => record.User?.name === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (text: string, record: MCreative) => (
        <Link to={`/applications/${record.applicationId}/creatives/${record.type === 'IEC' ? 'iec' : 'playable'}/${record.id}`}>
          {record.name}
        </Link>
      ),
      sorter: (a: MCreative, b: MCreative) => a.name.localeCompare(b.name || '') || -1,
      filters: getUniqueList(creatives, 'name').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MCreative) => record.name === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'Application Id',
      key: 'applicationId',
      dataIndex: 'applicationId',
      render: (text: string, record: MCreative) => (
        <Link to={`/applications/${record.applicationId}/creatives`}>
          {record.applicationId}
        </Link>
      ),
      sorter: {
        compare: (a: MCreative, b: MCreative) => a.applicationId - b.applicationId,
        multiple: 4,
      },
      filters: getUniqueList(creatives, 'applicationId').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MCreative) => record.applicationId === (value as number) || false,
      filterSearch: true,
    },
    {
      title: 'Application',
      key: 'application',
      dataIndex: 'application',
      render: (text: string, record: MCreative) => (
        <Link to={`/applications/${record.applicationId}/creatives`}>
          {record.Application?.name}
        </Link>
      ),
      sorter: (a: MCreative, b: MCreative) => a.Application?.name.localeCompare(b.Application?.name || '') || -1,
      filters: getUniqueList(creatives, 'Application.name').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MCreative) => record.Application?.name === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'Version',
      key: 'version',
      dataIndex: 'version',
      render: (text: string, record: MCreative) => (
        <label>{record.version}</label>
      ),
      sorter: (a: MCreative, b: MCreative) => a.version - b.version,
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      render: (text: string, record: MCreative) => (
        <label>{record.type}</label>
      ),
      sorter: (a: MCreative, b: MCreative) => a.type?.localeCompare(b.type || '') || -1,
      filters: getUniqueList(creatives, 'type').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MCreative) => record.type === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'Exported',
      key: 'exported',
      dataIndex: 'exported',
      fixed: 'right',
      width: '100px',
      align: 'center',
      render: (text: string, record: MCreative) => (
        <div>
          {record.androidIsExported && <AndroidFilled />}
          {record.iosIsExported && <AppleFilled />}
        </div>
      ),
    },
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'date',
      fixed: 'right',
      align: 'center',
      render: (text: string, record: MCreative) => (
        <label>{new Date(record.createdAt!).toLocaleString()}</label>
      ),
      sorter: (a: MCreative, b: MCreative) => a.createdAt?.toString()?.localeCompare(b.createdAt?.toString() || '') || -1,
    },
  ];

  return (
    <div id="creatives">
      <Spin spinning={loading}>
        <MpTable
          bordered
          urlParamsEnabled
          mpDisableTotal
          size="small"
          dataSource={creatives}
          columns={columns}
          rowKey="id"
          mpTableKey="creativesTable"
        />
      </Spin>
    </div>
  );
}
