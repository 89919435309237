import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { mpSdkService, documentationService } from '../../../../../../services';

import './step-one.scss';

export function StepOne() {
  const downloadLastSdk = () => {
    mpSdkService.lastSdk().then((data) => {
      const link = document.createElement('a');
      link.href = data?.Sdk?.url || '#';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }).catch(() => {});
  };

  const downloadLastDocumentation = () => {
    documentationService.lastDocumentation().then((data) => {
      const link = document.createElement('a');
      link.target = '_blank';
      link.href = data?.Documentation?.url || '#';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }).catch(() => {});
  };

  return (
    <div id="step-one">
      <div className="d-flex justify-content-around">
        <div className="text-center">
          <p>Download our SDK!</p>
          <Button type="primary" icon={<DownloadOutlined />} onClick={() => downloadLastSdk()}>
            SDK
          </Button>
        </div>
        <div className="text-center">
          <p>{'Don\'t forget to check the documentation !'}</p>
          <Button type="primary" icon={<DownloadOutlined />} onClick={() => downloadLastDocumentation()}>
            Documentation
          </Button>
        </div>
      </div>
    </div>
  );
}
